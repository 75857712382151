<template>
	

    <v-layout align-center justify-center wrap >
        <v-flex xs12 sm12 md10 lg10  >
        <v-layout wrap >
            <v-flex md4 sm12 xs12 lg4 v-for="app in categorias" :key="app.id" >
                <v-card
                class="elevation-12 ma-1 rounded-lg d-flex align-center justify-center"
                outlined
                @click="verCategoria(app.categoria)"
			    >
			    <v-img
                  contain
			      class="align-center text-center"
			      height="100px"
			      :src="getImageCategUrl(app.imgCategoria)" 
                  gradient="to top right, rgba(0,0,0,.20), rgba(0,0,0,.7)"
			    >
			      <span class="white--text" style="font-size:20px" >{{ app.categoria }}</span> 
			    </v-img>
                </v-card>
            </v-flex>
        </v-layout>
        </v-flex>
    </v-layout>

</template>


<script>
	import {mapState} from 'vuex'

export default {
	computed : {
    	...mapState(['categorias'])
    },
    data(){
        return {

        }
    },
    methods : {  
		verCategoria(item){
            this.$emit('productos-categoria', item)
        },
    }
}
</script>
