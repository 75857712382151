import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#000', 
          secondary: '#323031', 
          danger: '#F44336',
          anchor: '#8c9eff',
          white: '#ffffff',
          topbar: '#3F51B5',
          background: '#C28721',
          orange: '#036838',
          breadcrumbs: '#ffffff',
          carrito : '#436B62'
        },
      },
    },
    icons: {
      iconfont: 'mdi',
    },
  })

export default vuetify;
